import React from "react";
import BankIdFrame from "./BankIdFrame";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import CustomerLogo from "./images/CustomerLogo";

const AuthRoot: React.FC<{}> = () => {
  return (
    <Grid
      container
      sx={{
        backgroundImage: "url('/images/background.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100%",
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
        // boxSizing:"border-box"
        "@media (max-width: 1024px)":{
          backgroundImage:"none"
        }
      }}
    >
      <Grid
        item
        xl={3}
        sx={{
          backgroundColor: "#003366",
          backgroundImage: "url('/images/waves.png')",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          pl:"1rem",
          pr:"1rem",
          "@media (max-width: 1024px)":{
            width:"100vw"
          }
        }}
      >
        <Stack
          spacing={3}
          sx={{
            mt:"108px",
            "@media (max-width: 1024px)":{
                mt:0,
                position:"relative",
                top:"50%",
                transform:"translateY(-50%)"
            }
            //height: "100%",
          }}
        >
          {/* <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flex: 1,
            }}
          > */}
            <CustomerLogo
              sx={{
                height:"107px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          {/* </Box> */}
          <BankIdFrame
            sx={{
             /* marginLeft: "48px !important",
              /marginRight: "48px !important",*/
              left: "50%",
              transform: "translateX(-50%)",
              position: "relative",
              overflow: "visible",
              borderRadius:"8px",
              width:"288px",
            }}
          />
          {/* <Box sx={{ flex: 1 }} /> */}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AuthRoot;
