import React from "react";
import { Box, BoxProps } from "@mui/material";

const CustomerLogo: React.FC<BoxProps> = (props) => {
  return (
    <Box component="img" src="/images/hsblogo.svg" alt="HSB Logo" {...props} />
  );
};

export default CustomerLogo;
